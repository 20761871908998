<template>
  <div class="syssetting">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>认证管理</BreadcrumbItem>
          <BreadcrumbItem>企业认证</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div class="ser-m">
      <Form ref="formInline" inline :label-width="70">
        <FormItem label="用户ID">
          <Input type="text" @on-keyup.13="ser" v-model="serData.user_id" placeholder="用户ID" maxlength="10"></Input>
        </FormItem>
        <FormItem label="用户手机">
          <Input type="text" @on-keyup.13="ser" v-model="serData.phone" placeholder="用户手机" maxlength="11"></Input>
        </FormItem>
        <FormItem label="微信昵称">
          <Input type="text" @on-keyup.13="ser" v-model="serData.wx_name" placeholder="微信昵称" maxlength="30"></Input>
        </FormItem>
        <FormItem label="企业名称">
          <Input type="text" @on-keyup.13="ser" v-model="serData.cname" placeholder="企业名称" maxlength="30"></Input>
        </FormItem>
        <FormItem label="信用代码">
          <Input type="text" @on-keyup.13="ser" v-model="serData.cnum" placeholder="信用代码" maxlength="30"></Input>
        </FormItem>
        <FormItem label="银行账号">
          <Input type="text" @on-keyup.13="ser" v-model="serData.cbank_num" placeholder="银行账号" maxlength="30"></Input>
        </FormItem>
        <FormItem label="企业支付宝">
          <Input type="text" @on-keyup.13="ser" v-model="serData.calipay" placeholder="企业支付宝" maxlength="30"></Input>
        </FormItem>
        <FormItem label="审核状态">
          <Select style="width: 120px" v-model="serData.status" @on-change="ser">
            <Option :value="3">全部</Option>
            <Option :value="2">待审核</Option>
            <Option :value="1">审核通过</Option>
            <Option :value="0">审核失败</Option>
          </Select>
        </FormItem>
        <FormItem label="审核时间">
          <DatePicker type="daterange" placeholder="审核时间" v-model="serData.exam_at" style="width: 200px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem label="更新时间">
          <DatePicker type="daterange" placeholder="更新时间" v-model="serData.re_time" style="width: 200px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" @click="ser" @keyup.13="ser">搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot-scope="item" slot="user_id">
        <div class="user-msg">
          <div class="head-img"><viewer :options="{'url': 'data-source'}"><img :src="item.row.head_img+'?x-oss-process=style/headimg'" :data-source="item.row.head_img" /></viewer></div>
          <div class="user-l-item">
            <div>昵称：<span>{{item.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{item.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{item.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in item.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[item.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{item.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{item.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{item.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{item.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="item" slot="status">
        <div v-if="item.row.status == 1">
          <Tag color="success">审核通过</Tag>
        </div>
        <div v-else-if="item.row.status == 2">
          <Tag color="warning">待审核</Tag>
        </div>
        <div v-else>
          <Tag color="error">审核未通过</Tag>
          <div style="max-width: 150px;white-space: normal; ">原因：{{item.row.msg}}</div>
        </div>
      </template>
      <template slot-scope="item" slot="action">
        <Button size="small" type="primary" @click="editCompany(item.index)">编辑</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="page_size" :page-size-opts="[15,30,50]" @on-change="change_page" @on-page-size-change="change_page_size"/>
    </div>
    <edit-company ref="editCompanyRef" @saveOk="saveOk"></edit-company>
  </div>
</template>
<script>
import editCompany from "@/views/user_auth/edit_company";
export default {
  name: "Company",
  components:{
    editCompany
  },
  data(){
    return {
      columns:[
        {title: '会员信息',slot: 'user_id',minWidth:180,'fixed':'left'},
        {title: '企业名称',key: 'cname',minWidth:120,'fixed':'left'},
        {title: '信用代码',key: 'cnum',minWidth:120},
        {title: '开户行',key: 'cbank',minWidth:100},
        {title: '银行账户',key: 'cbank_num',minWidth:150},
        {title: '企业支付宝',key: 'calipay',minWidth:150,
          render: (h, params) => {
              return  h('span', params.row.calipay == ''?'--':params.row.calipay);
          }
        },
        {title: '审核时间',key: 'exam_at',minWidth:140,
          render: (h, params) => {
            return  h('span', params.row.exam_at == ''?'--':params.row.exam_at);
          }
        },
        {title: '更新时间',key: 're_time',minWidth:140},
        {title: '审核状态',slot: 'status',minWidth:100,'fixed':'right'},
        {title: '操作',slot:'action',width:100,align:'center','fixed':'right'},
      ],
      data:[],
      serData:{name:'',status:3},
      page:1,
      page_size:0,
      total:0,
      loading:false,
      editStatus:false,
      checkIndex:-1,
      editData:{id:0, name:'',code:'',status:1, is_def:0},
      cfg:{platform:{},level:{}}
    }
  },
  created() {
    let query={};
    if(typeof this.$route.query.status!="undefined"){
      this.serData.status=parseInt(this.$route.query.status);
      query.status=this.$route.query.status||3;
    }
    this.$router.push({query});
    this.$store.commit('add_tagsview',this.$route);
    this.init();
  },
  watch:{
    '$route.query':function (query){
      if(query.newpage==1){
        query={'status':query.status||3};
        query.status=parseInt(query.status);
        Object.assign(this.serData,query);
        this.$router.push({query});
        this.$store.commit('add_tagsview',this.$route);
        this.init();
      }
    }
  },
  methods:{
    init:function(){
      let _this = this;
      this.loading = true;
      this.requestApi('/adm/getCompany',{page_size:this.page_size,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.total = res.data.page.total;
          _this.current_page = res.data.page.current;
          _this.page_size = res.data.page.limit;
          _this.data = res.data.list;
          _this.cfg = res.data.cfg;
        }
        _this.$nextTick(()=>{
          _this.checkIndex = -1;
        })
      })
    },
    ser:function(){
      this.page = 1;
      this.init();
    },
    change_page:function (page){
      this.page = page;
      this.init();
    },
    change_page_size:function (pageSize){
      this.page_size = pageSize;
      this.page = 1;
      this.init();
    },
    editCompany:function(index){
      this.checkIndex = index;
      this.$refs.editCompanyRef.open(this.data[index],index);
    },
    saveOk:function(){
      if(this.checkIndex != -1) this.page = 1;
      this.init();
    },

  }
}
</script>

<style lang="less">
.user-msg{
  display: table; width: auto;height: 100px;
  .head-img{
    display: table-cell; width: 40px;height: 40px;border-radius: 20px;vertical-align: middle;
    img{
      width: 40px;height: 40px;border-radius: 20px; cursor: zoom-in;
    }
  }
  .user-l-item{
    display: table-cell; width: auto;vertical-align: middle;padding-left: 5px;
    span{
      color: #2d8cf0;
    }
  }
}
</style>